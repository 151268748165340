ul {
  list-style: none;
  margin-right: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
p {
  margin: 0;
  padding: 0;
}

.text-primary {
  color: #27ca84 !important;
}

.btn {
  box-shadow: none !important;
}

.f-12 {
  font-size: 12px;
}

.pointer {
  cursor: pointer;
}

.upload__image-wrapper {
  position: relative;
  width: 100%;
  /* height: 280px; */
  /* border: 1px solid #000; */
  border-radius: 30px;
}

.upload__image-wrapper input {
  width: 100%;
  height: 100%;
  appearance: none;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.upload__image-wrapper span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  z-index: -1;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.uploadedImagge {
  position: absolute;
  top: 50%;
  left: 50%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.VideoInput_footer {
  opacity: 0;
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
  transition: 0.5s ease;
  transition-property: color, background-color, border-color, box-shadow;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.asset__btn--full {
  width: 100%;
}

.asset__btn-fixed {
  width: 220px !important;
  margin-top: 0;
}

.asset__btn {
  width: calc(50% - 10px);
  /* height: 60px; */
  border-radius: 12px;
  background-color: #222227;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  padding: 0 20px;
  margin-top: 20px;
  margin-right: 20px;
}

.asset__btn--clr {
  background: radial-gradient(circle at top right, #11998e, #27ca84);
  color: #fff;
}

.asset__btn--clr:hover {
  /* background-color: #222227; */
  color: #000;
}

.asset__btn--smc {
  width: 45px;
  min-width: 45px;
  height: 60px;
  border-radius: 50%;
  background: #fff;
  transition: 0.3s ease-in-out;
}

.asset__btn--smc:hover {
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px;
}

.sign__group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.w-25px {
  width: 25px;
}

.dropdown-item {
  color: #000;
  transition: 0.3s !important;
}

.dropdown-item-md {
  padding: 15px 10px;
  border-bottom: 1px solid #c1c1c1;
}

.dropdown-menu-md {
  width: 250px;
  height: 350px;
  padding: 0;
  margin: 5px 0;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.07);
  cursor: pointer;
  overflow-y: auto;
}

.dropdown-menu-md .dropdown-item.active,
.dropdown-menu-md .dropdown-item:active,
.dropdown-menu-md .dropdown-item:hover {
  color: #27ca84;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  background-color: #fff !important;
}

.closer__asset {
  color: #000;
}

.closer__asset:hover {
  color: #fff;
}

.sign__input {
  background-color: #fff;
  border: 1px solid #c1c1c1;
  border-radius: 12px;
  height: 46px;
  position: relative;
  color: #000;
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  font-family: "Work Sans", sans-serif;
  outline: none;
}

.sign__input:focus {
  border-color: #27ca84;
}

.input__field--sm {
  background-color: #fff;
  border: 1px solid #c1c1c1;
  border-radius: 12px;
  width: 100%;
  height: 46px;
  position: relative;
  color: #000;
  font-size: 16px;
  padding: 0 10px;
  width: 100%;
  font-family: "Work Sans", sans-serif;
}

.input__field--sm input {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  border: none;
  outline: none;
  background: none;
  appearance: none;
}

.input__field--sm input::-webkit-outer-spin-button,
.input__field--sm input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input__field--md-area {
  width: 100%;
  height: 100px;
}

.input__field--sm textarea {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  border: none;
  outline: none;
  background: none;
  appearance: none;
  resize: none;
}

/* Firefox */

.input__field--sm input[type="number"] {
  -moz-appearance: textfield;
}

.add__Address--field-item {
  margin: 10px 0;
}

.dropdown__main--wrap {
  width: 100%;
}

.drop__menu--1--btnbig,
.drop__menu--2--btnbig {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drop__menu--1--btnbig .dropdown-item,
.drop__menu--2--btnbig .dropdown-item {
  padding: 0;
  background: transparent;
}

.drop__menu--1--blockc,
.drop__menu--2--blockc {
  width: 100%;
  height: auto;
  max-height: 350px;
  padding: 0;
  margin: 5px 0;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.07);
  cursor: pointer;
  overflow-y: auto;
}

.blockc-msbs {
  width: 100%;
  /* height: 350px; */
  padding: 0;
  margin: 5px 0;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.07);
  cursor: pointer;
  overflow-y: auto;
}

.drop__menu--1--blockc .dropdown-item,
.drop__menu--2--blockc .dropdown-item,
.blockc-msbs .dropdown-item {
  width: 100%;
  padding: 10px 10px;
  border-bottom: 1px solid #c1c1c1;
}

.drop__menu--1--blockc .dropdown-item:hover,
.drop__menu--1--blockc .dropdown-item.active,
.drop__menu--1--blockc .dropdown-item:active,
.drop__menu--2--blockc .dropdown-item:hover,
.drop__menu--2--blockc .dropdown-item.active,
.drop__menu--2--blockc .dropdown-item:active,
.blockc-msbs .dropdown-item:hover,
.blockc-msbs .dropdown-item.active,
.blockc-msbs .dropdown-item:active {
  color: #27ca84;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  background-color: #fff !important;
}

.added__tokens--wrap {
  position: relative;
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.added__token--box {
  position: relative;
  width: 200px;
  min-height: 48px;
  padding: 10px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid rgb(229, 232, 235);
  justify-content: center;
  cursor: default !important;
}

.added__token--box .dropdown-item {
  background-color: transparent;
  color: #000;
}

.added__token--box:hover {
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  background: #fff;
}

.xsm-icon {
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
}

.wallet__list--wrapper {
  width: 75%;
}

@media (max-width: 991px) {
  .wallet__list--wrapper {
    width: 100%;
  }
}

.wallete__list--button {
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 30px;
  overflow: hidden;
}

.wallete__list--button li button {
  width: 100%;
  padding: 15px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wallete__list--button li button:hover {
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
}

.badge-1 {
  padding: 3px 10px;
  border-radius: 10px;
}

.load-function {
  display: none;
}

.earning__table--wrapper {
  position: relative;
  width: 100%;
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.07);
}

.table__head {
  padding: 15px 20px;
  border-bottom: 1px solid rgb(229, 232, 235);
}

.earning__table--module {
  width: 100%;
  height: 100%;
  min-height: 500px;
  overflow-x: auto;
}

.table__row--wrapper {
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 1;
  display: flex;
  border-bottom: 1px solid rgb(229, 232, 235);
}

.row__cell {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex: 1 0 100px;
  padding: 5px 4px;
  flex-basis: 100px;
}

.row__cell:first-child {
  padding-left: 16px;
  flex-basis: 300px;
}

@media (max-width: 991px) {
  .row__cell {
    flex-basis: 150px;
  }

  .row__cell:first-child {
    flex-basis: 200px;
  }
}

@media (max-width: 600px) {
  .row__cell:first-child {
    flex-basis: 80px;
  }
}

.table__heading--row {
  font-weight: bold;
}

.empty__table--row {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.empty__data--text {
  font-size: 18px;
  margin-top: 5px;
}

.tab__toggler--module {
  position: relative;
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.tab__toggler--button {
  position: relative;
  width: 50%;
  height: 100px;
  transition: 0.3s;
}

.tab__toggler--button:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.tab__toggler--button.active {
  background: radial-gradient(circle at top right, #11998e, #27ca84);
  color: #fff;
}

.form-control {
  box-shadow: none !important;
  height: 60px;
  border-radius: 10px;
}

.form__wrap--mod {
  width: 100%;
  gap: 10px;
}

@media (max-width: 575px) {
  .form__wrap--mod {
    text-align: center;
  }
}

.fm__control {
  width: 100%;
  height: 60px;
  border-radius: 5px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 15px;
}

.form-control:focus,
.fm__control:focus {
  border-color: #27ca84;
}

.input-group {
  height: 60px;
  border-radius: 10px;
}

.input-group .dropdown-toggle {
  width: 150px;
  border-radius: 10px;
}

.fm-dropdown {
  width: 150px;
  padding: 0;
  overflow: hidden;
}

.fm-dropdown .dropdown-item {
  padding: 10px 15px;
}

.fm-dropdown .dropdown-item:hover,
.fm-dropdown .dropdown-item.active,
.fm-dropdown .dropdown-item:active,
.fm-dropdown .dropdown-item:hover,
.fm-dropdown .dropdown-item.active,
.fm-dropdown .dropdown-item:active {
  color: #27ca84;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  background-color: #fff !important;
}

.btn-outline-success {
  height: 60px !important;
  background: #27ca84 !important;
  border: 1px solid #27ca84 !important;
  color: #000 !important;
}

.durition__box--module {
  position: relative;
  width: 100%;
  height: 60px;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 10px;
}

.durition__box--module:focus {
  border-color: #27ca84 !important;
}

.durition__box--drop {
  position: absolute;
  top: 50px;
  width: 100%;
  padding: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 10px;
  margin: 10px 0;
  background: #fff;
  z-index: 2;
  display: none;
}

.durition__box--drop.show {
  display: block;
}

.custom-control-input {
  width: 20px !important;
}

.rbox__sticky--mod {
  position: sticky;
  top: 50px;
}

.nft__sell--image-box {
  width: 400px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 10px;
}

.nft__sell--image-box .seller__nft--image {
  position: relative;
  width: 100%;
  height: 350px;
}

.nft__sell--image-box .seller__nft--image img {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: contain;
}

.wd__content--wrapper {
  width: 400px;
  display: none;
}

.wd__content--wrapper.rmHider {
  display: block;
}

.redirected__main--mod {
  position: relative;
  width: 100%;
  padding: 35px 20px;
  color: #000 !important;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  transition: 0.3s;
}

.redirected__main--mod:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.redirected__img--box {
  width: 70px;
  margin-right: 20px;
}

@media (max-width: 991px) {
  .rbox__sticky--mod {
    position: relative;
    top: 0;
    margin-bottom: 30px;
  }

  .nft__sell--image-box {
    width: 100%;
  }

  .nft__sell--image-box .seller__nft--image {
    position: relative;
    width: 100%;
    height: 80px;
  }

  .wd__content--wrapper {
    width: 100%;
  }
}

.seller__nft--details {
  position: relative;
  padding: 30px 10px 60px;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 2.75rem;
  pointer-events: all;
  border-radius: 1rem;
}

.custom-switch .custom-control-label::after {
  left: calc(-1.5rem + 2px);
}

.custom-switch .custom-control-label::after {
  top: calc(0.35rem + 2px);
  left: calc(-2.15rem + 2px);
  width: calc(1.2rem - 4px);
  height: calc(1.2rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(1.3rem);
}

.custom-control-label::before {
  height: 1.5rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #17a68b;
  background-color: #17a68b;
}

.unlock-switch {
  /* margin-top: 16px; */
  /* border-bottom: 1px solid rgb(229, 232, 235); */
  padding-bottom: 16px;
}

.unlock-switch .asset--form {
  margin-top: 0;
  border-bottom: none;
  padding-bottom: 0;
}

.more__options--container-1,
.more__options--container-2 {
  display: none;
}

.more__options--container-1.show,
.more__options--container-2.show {
  display: block;
}

.more__otp--btn.active i {
  transform: rotate(180deg);
}

.more__opt--wrap--container {
  display: none;
}

.more__opt--wrap--container.show {
  display: block;
}

.sale__container--box {
  display: none;
}

.sale__container--box.active {
  display: block;
}

.author__meta .nav-pills .nav-link.active,
.author__meta .nav-pills .show > .nav-link {
  text-align: left !important;
}

.author__meta .nav-pills .nav-item .nav-link {
  text-align: left !important;
}

.page_loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #17a68b;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 50px;
    left: 50px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.manage_audio-player {
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: fill;
  background-size: 50%;
  background-position: center;
}

.manage_audio-player-sm {
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: fill;
  /* background: url('../img/audio-background.png') no-repeat; */
  background-color: #000;
  background-size: 50%;
  background-position: center;
}

.finish-data--message {
  position: relative;
  width: 100%;
  padding: 20px 10px;
  border-radius: 10px;
  background-color: #27ca84;
  margin-top: 30px;
  text-align: center;
  color: #fff;
}

.list-group-item input {
  width: calc(100% - 40px) !important;
}

.btn-loading {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  background: radial-gradient(circle at top right, #11998e, #27ca84);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  margin: 15px auto;
}

.btn-loading:hover {
  color: #000;
}

.spinner-border {
  width: 20px !important;
  height: 20px !important;
  border-width: 3px !important;
}

.sign__group--error {
  color: #f00000;
  margin-top: 5px;
  margin-left: 5px;
  font-size: 14px;
}

.if_isAudio {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
}

.collection-card {
  min-height: 250px;
}

.wallets-container.svelte-11upx36 {
  display: grid;
  grid-template-columns: repeat(var(--onboard-wallet-columns, 2), 1fr);
  padding: 10px;
  width: 100%;
}

.icon.svelte-1wcty06 {
  height: 100%;
}

/* .justify-center {
	justify-content: center;
} */

.background-transparent.svelte-1wcty06 {
  background: transparent;
}

.name.svelte-ucnmri {
  margin-left: 4px;
}

.dark-theme button.svelte-ucnmri {
  background-color: #0e0e23;
  border: 1px solid #ffba00;
  transition: background-color 250ms ease-in-out;
  color: #fff;
}

button.svelte-ucnmri {
  /* background-color: #000; */
  border: 1px solid #ffba00;
  transition: background-color 250ms ease-in-out;
}

.card-content > .modal-card {
  margin: 1%;
  width: 48%;
  border-radius: 7px;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  padding: 23px 0;
  float: left;
  color: #fff;
  background: transparent;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  cursor: pointer;
  box-shadow: 10px 10px 5px 0 rgb(0 0 0 / 11%);
  border: 1px solid #fff;
}

.modal-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  /* background-color: #000; */
  background-clip: border-box;
  border: none;
  /* border-radius: 0.25rem; */
  margin: 10px;
}

.modal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 24px;
  cursor: pointer;
  font: inherit;
  border: none;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
}

.modal-btn:hover {
  background-color: #4a87f094;
  color: #000;
}

body.main-body iframe {
  width: 0 !important;
  display: none;
}

/* USER SOCIAL MEDIA */
.user__social--media {
  position: absolute;
  right: 20px;
  top: 20px;
}

.user__social--media .btn-outline-secondary {
  border-color: #e5e8eb;
  font-size: 18px;
}

.user__social--media .btn-outline-secondary:hover,
.user__social--media .btn-outline-secondary:active {
  background: radial-gradient(circle at top right, #11998e, #27ca84);
}

@media (max-width: 376px) {
  .user__social--media {
    position: relative;
    right: 0;
    top: 0;
  }
}

.social__media-icons-wrapper--modssds {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.social__media-icons-wrapper--modssds .social-media-icon__dcxs {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 0.05),
    inset -2px -2px 10px rgba(0, 0, 0, 0.05) !important;
  background-color: #fff;
}

.social__media-icons-wrapper--modssds .social-media-icon__dcxs:hover {
  background-color: #17a68b;
  color: #fff;
}

/* LOADER */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #17a68b;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.explicit_icon--image {
  position: relative;
  left: 0;
  top: 0;
  width: 100px;
}

/* MODAL WALLET CONNECT */
.wallet__wrapper--modal-container {
  display: flex;
  flex-direction: column;
}
.wallet__wrapper--modal-container .wallet__connect--button-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 8px;
  border: 1px solid #11998e;
  color: #fff;
  border-radius: 4px;
  margin-bottom: 10px;
}

/* CARD */
.staking_card {
  position: relative;
  width: 100%;
  background-color: transparent !important;
  border-radius: 20px;
}

.staking_card .card-inner {
  position: relative;
  width: 100%;
  padding: 20px;
  --text-color: #fff;
  --blur-size: 1.5px;
  background: hsl(0, 0%, 100%, 0.08);
  border: 1px solid hsl(0, 0%, 100%, 0.08);
  border-right: 3px solid hsl(0, 0%, 100%, 0.08);
  border-bottom: 3px solid hsl(0, 0%, 100%, 0.08);
  border-radius: 20px;
}

.staking_card .card-inner .card-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.staking_card .card-inner .card-top img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.staking_card .card-inner .card-top .card-typeBadge {
  padding: 2px 12px;
  font-size: 14px;
  background-color: #fff;
  color: #11998e;
  border-radius: 8px;
}

.staking_card .card-inner .card-content .card-ul li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 20px 0;
}

.staking_card .card-inner .card-content .card-ul li button {
  color: #fff !important;
}

.staking_card .card-inner .card-content .card-ul li .btn-harvest {
  border: 0 !important;
  color: #fff !important;
  border-radius: 4px !important;
  background: radial-gradient(circle at top right, #11998e, #38ef7d) !important;
}

.staking_card .card-inner .card-content .card-ul li .btn-harvest:hover {
  background: rgba(74, 0, 224, 0.1);
}

.staking_card .card-inner .card-content .card-ul li .btn-secondary {
  /* min-width: 45px; */
  border-radius: 0 15px !important;
}

.staking_card .card-inner .card-content .card-button button,
.card-button-swap button:not(.btn-gray) {
  border: 0 !important;
  width: 100%;
  padding: 8px 26px;
  font-weight: 500;
  color: #fff;
  border-radius: 12px;
  text-align: center !important;
  background: radial-gradient(circle at top right, #11998e, #38ef7d) !important;
  text-align: center;
}

.card-button-swap .btn-gray {
  border: 0 !important;
  width: 100%;
  padding: 8px 26px;
  font-weight: 500;
  color: #fff;
  border-radius: 12px;
  text-align: center !important;
  text-align: center;
}

.staking_card .card-inner .card-content .btn-secondary {
  width: 40px;
  height: 40px;
  padding: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.staking_card .card-inner .card-content .btn-secondary img {
  width: 25px;
  height: 25px;
}

/* sm_select_list */
.sm_select_list {
  min-height: 30px;
  outline: 0;
}

.form-group label {
  display: block;
  margin-bottom: 6px;
}

.form-group input {
  position: relative;
  width: 100%;
  height: 45px;
  padding: 0 10px;
  color: #fff;
  background: transparent;
  border: 1px solid #11998e;
  border-radius: 10px;
  outline: 0;
  box-shadow: none;
}

.card-button .iekbcc0.ju367va.ju367v1s {
  justify-content: center !important;
}

/* CONNECT BUTTON */
.ju367v1s {
  margin: auto !important;
}
.ju367v1s .iekbcc9 {
  background: radial-gradient(circle at top right, #11998e, #38ef7d) !important;
  color: #fff !important;
}

.iekbcc0.ju367v4.ju367va.ju367v1p {
  color: #fff !important;
}
