

.main {
  margin-top: 110px;
  padding: 0 0 60px;
  position: relative;
}
.wallet-section .main__title--page {
  margin-bottom: 0;
  margin-top: 50px;
}

.wallet-section .main__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 60px;
}
.wallet-section .main__title h1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  color: #fff;
  line-height: 140%;
  margin-bottom: 0;
  position: relative;
  font-size: 40px !important;
}
.wallet-section .main__title p {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  color: #FFF;
  margin-top: 20px;
  margin-bottom: 0;
}
.wallet-section .wallet__item {
  border: 1px solid #c1c1c1;
  border-radius: 16px;
  margin-top: 2px;
  position: relative;
  transition: all .3s ease-out 0s;
}
 
.wallet-section .wallet__item-fix {
  border-radius: 14px;
  overflow: hidden;
  padding: 30px 25px 25px;
  position: relative;
}
.wallet-section .wallet__popular {
  background: radial-gradient(circle at top right,#11998e,#27ca84);
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  height: 20px;
  line-height: 20px;
  min-width: 135px;
  position: absolute;
  right: -38px;
  text-align: center;
  top: 25px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.wallet-section .wallet__item-inner {
  position: relative;
  z-index: 1;
}
.wallet-section .wallet__title {
  color: #FFF;
  font-size: 18px;
  font-weight: 600;
}
.wallet-section .modal-content .close{
  color: #000;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    opacity: .5;
    text-shadow: 0 1px 0 #fff;
}
.wallet-modal .modal-body
{
  padding: 18px 10px 18px 15px;
}
.wallet-modal .modal-content button.close {
  background-color: transparent;
  border: 0;
  padding: 0;
  position: absolute;
  right: 32px;
  top: 13px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  color: #fff;
}
.wallet-modal .modal-content button.close{
  font-size: 30px;
}
.wallet-modal .close:before, .close:after{
  display: none;
}
.wallet-modal  .modal-content{
  width: 100% !important;
}
.wallet-modal .modal-content .text-body{
  font-size: 24px;
  font-weight: 500;
  color: #fff !important;
}
.wallet-modal .proceed-btn{
  margin: 20px 0;
  width: 100%;
}
.wallet-modal .filter__checkboxes li input{
  margin-right: 10px;
}
.liquidity-section-dj .container {
  max-width: 450px;
}

.liquidity-section-dj .liquditiy-title {
  margin: 30px 0 20px;
}

.liquidity-section-dj .liquditiy-title .title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #f1f1f2;
}
.liquidity-section-dj .card .card-body button {
  margin-bottom: 10px;
}
.liquidity-section-dj .card .card-body button:hover {
  color: #27c982;
}
.liquidity-section-dj .card .card-body span {
  font-size: 14px;
  color: #f1f1f2;
  line-height: 1.5;
  font-variant: tabular-nums;
  font-weight: 600;
  margin-top: 10px;
}
/* table css pool page */
.common-table {
  margin: 50px 0;
}
.common-table .jAJmuq {
  border-radius: 0px;
  padding: 0px 17px;
  background: transparent;
  border-radius: 0;
}
.common-table .fvGSbZ,
.common-table .dSEQKL,
.common-table .iVVpQr {
  width: 100%;
  border: 1px solid #27ca84;
  color: #fff;
  border-radius: 6px;
}
.common-table .iVVpQr {
  padding: 5% 3%;
}
.common-table label {
  display: inline-block;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
/* .common-table select {
  margin-left: 10px;
  background: #27ca84;
  color: #fff;
  border: 0;
  font-size: 14px;
  font-weight: 600;
} */
.common-table .jZNukK th {
  background-color: transparent;
  padding: 0 10px;
  border-bottom: 1px solid #27ca84;
}
.common-table .kkHOgo {
  border-bottom: 1px solid #27ca84;
}
.common-table .kkHOgo:nth-child(even) {
  background-color: transparent;
}
.common-table .kkHOgo td {
  text-align: left;
  padding-left: 10px;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
}
.common-table .rYdUT {
  border: 1px solid #27ca84;
}
.common-table .kkHOgo:hover {
  background-color: #27ca84;
}
.sc-iqcoie {
  display: inline;
  margin-left: 3px;
}
.sc-iqcoie svg {
  margin-right: 2px;
}
@media screen and (max-width: 768px) 
{
  .main__title p:last-child, .main__title--sidebar {
    margin-bottom: 10px;
}
.main {
  padding: 0 0 70px;

}
}
@media screen and (max-width: 767px) {
  .common-table .ePkUEd {
    width: 48%;
  }
  .main__title h1 {
    font-size: 40px;
  }
}
