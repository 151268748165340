.tokens-box{
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .popup-wrapper{
    position: relative;
    display: flex;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
  }
  .Popup{
    position: relative;
    width: 500px;
    height: 500px;
    min-height: 500px;
    background: #1d2253;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 20px;
    border-radius: 10px;
  }
  .Popup .close-btn{
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
  .Popup .head{
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
   .searchBx{
    position: relative;
    width: 100%;
    height: 50px;
    margin: 20px 0;
  }
   .searchBx  input{
    width: 100%;
    height: 100%;
    padding: 10px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: none;
    outline: none;
    transition: .3s;
  }
   .searchBx  input:focus{
    border: 1px solid rgba(255, 255, 255, 0.8);
  }
   .tokens-box{
    position: relative;
    width: 100%;
    height: 320px;
    padding: 0;
    border: 1px solid rgba(255, 255, 255, 0.3);
    overflow-y: hidden;
  }
   .tokens-box::-webkit-scrollbar{
    display: none;
  }
   .tokens-box li{
    padding: 15px 10px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
  }
   .tokens-box li a{
    color: #fff;
    text-decoration: none;
  }
   .tokens-box li:hover{
    background: rgba(0, 0, 0, 0.1);
  }
  .btn{
    box-shadow: none !important;
  }
  .btn-1{
    background: radial-gradient(circle at top right,#11998e,#38ef7d) !important;
    border: none !important;
    border-radius: 8px;
    color: #fff !important;
    font-weight: bold !important;
    box-shadow: 0 0 100px 0 #dce35b70;
    padding: 10px !important;
    transition: all .3s;
  }
  .btn-1:hover{
    color: #000 !important;
  }
  
  .popup-wrapper{
    position: relative;
    display: flex;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
  }
  .Popup{
    position: relative;
    margin: 0 auto;
    width: 600px;
    height: auto;
    min-height: 300px;
    background: #1d2253;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 20px;
    border-radius: 5px;
  }
  .Popup .close-btn{
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
  .Popup lord-icon{
    width: 200px;
    height: 200px;
  }
  #post-confirmation{
    display: none;
  }
  .inner-content-box{
    width: 100%;
    height: 200px;
    padding: 20px;
    background: rgba(0,8,41,.3);
    text-align: left;
  }
  .Popup lord-icon{
    width: 200px;
    height: 200px;
  }
  #post-confirmation{
    display: none;
  }
  
  .btn-2{
    position: relative;
    background: #1D1253 !important;
    border: none !important;
    border-radius: 8px;
    color: #38EF7D !important;
    font-weight: bold !important;
    box-shadow: 0px 0px 100px 0 #000 !important;
    padding: 10px !important;
    transition: all .3s;
  }
  .btn-2:hover{
    color: #000 !important;
  }
  .btn-2::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1.5px #38EF7D;
    mask-image: radial-gradient(at -31% -58%,rgba(0,0,0,.5) 34%,
                transparent 60%),linear-gradient(270deg,rgba(0,0,0,.2),
                transparent 13%),linear-gradient(rgba(0,0,0,.05),
                rgba(0,0,0,.05));
  }
   .fm-control{
    width: 50%;
    height: 40px;
  }
  .fm-control input{
    width: 100% !important;
    height: 100% !important;
    background: none !important;
    color: #fff !important;
    font-weight: bold !important;
    font-size: 30px !important;
    text-align: center !important;
    border: none !important;
    border-bottom: 1px solid rgba(255, 255, 255, .2) !important;
    outline: none !important;
    appearance: none !important;
  }
   .fm-control input[type=number]{
    -moz-appearance: textfield !important;
  }
   .fm-control input::-webkit-outer-spin-button,
   .fm-control input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
  #WEB3_CONNECT_MODAL_ID .web3modal-modal-lightbox{
    background-color: rgba(0, 0, 0, 0.0)
  }